<template>
  <v-flex>
    <v-select
      class="mr-2"
      :items="situacoes"
      item-value="Id"
      item-text="Situacao"
      label="Situação"
      v-model="situacao"
      :rules="situacaoRule"
      @input="setSelected"
    ></v-select>
  </v-flex>
</template>
<script>
import { serverBus } from "../../main"
import SituacoesVendedor from "../../domain/vendedor/SituacoesVendedor"
export default {
  data: () => ({
    situacoes: [],
    situacao: "",
    situacaoRule: [v => !!v || "Campo obrigatório"]
  }),
  created() {
    var ativo = new SituacoesVendedor()
    ativo.Id = 1
    ativo.Situacao = "ATIVO"

    var bloqueadoFaltaPagamento = new SituacoesVendedor()
    bloqueadoFaltaPagamento.Id = 2
    bloqueadoFaltaPagamento.Situacao = "BLOQUEADO POR FALTA DE PAGAMENTO"

    var bloqueadoNaoDevolucao = new SituacoesVendedor()
    bloqueadoNaoDevolucao.Id = 3
    bloqueadoNaoDevolucao.Situacao = "BLOQUEADO POR NAO DEVOLUCAO"

    var bloqueadoNomeNegativado = new SituacoesVendedor()
    bloqueadoNomeNegativado.Id = 4
    bloqueadoNomeNegativado.Situacao = "BLOQUEADO POR CPF NEGATIVADO NO SPC"
    
    var parouVender = new SituacoesVendedor()
    parouVender.Id = 5
    parouVender.Situacao = "PAROU DE VENDER"

    this.situacoes.push(ativo)
    this.situacoes.push(bloqueadoFaltaPagamento)
    this.situacoes.push(bloqueadoNaoDevolucao)
    this.situacoes.push(bloqueadoNomeNegativado)
    this.situacoes.push(parouVender)

    serverBus.$on("carregarSituacaoSelecionada", value => {
      this.situacao = value
    })
  },
  methods: {
    setSelected(value) {
      serverBus.$emit("situacaoSelected", value)
    }
  }
}
</script>
