<template>
  <v-flex>
    <v-form ref="form">
      <div class="d-flex">
        <v-switch
          v-if="sempreAtivo"
          v-model="switch1"
          :label="`Status: ${switch1 == true ? 'ATIVO' : 'BLOQUEADO'}`"
          :readonly="sempreAtivo"
        ></v-switch>
        <select-situacoes v-else></select-situacoes>
        <select-praca v-if="!sempreAtivo" class="mr-2"></select-praca>
      </div>
      <div class="d-flex">
        <v-text-field
          class="mr-2"
          label="CPF"
          name="CPF"
          placeholder="###.###.###-##"
          v-model="vendedor.cpf"
          v-mask="'###.###.###-##'"
          @blur="validaCpfCadastrado"
          :rules="cpfRules"
          :error-messages="cpfValido == 'error_outline' ? 'CPF já cadastrado, consulte o cadastro de vendedores' : ''"
          :append-icon="cpfValido"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          label="Nome"
          name="Nome"
          placeholder="Nome"
          v-model="vendedor.nome"
          :rules="nomeRules"
        ></v-text-field>
      </div>
      <div class="d-flex">
        <v-text-field class="mr-2" label="RG" name="RG" placeholder="RG" v-model="vendedor.rg"></v-text-field>
        <v-text-field
          class="mr-2"
          label="Telefone"
          name="Telefone"
          placeholder="(##) #####-####"
          v-model="vendedor.telefone1"
          v-mask="'(##) #####-####'"
          :rules="telefoneRules"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          label="Telefone"
          name="Telefone"
          placeholder="(##) #####-####"
          v-model="vendedor.telefone2"
          v-mask="'(##) #####-####'"
        ></v-text-field>
        <v-menu
          class="pr-2"
          ref="dtNasc"
          lazy
          :close-on-content-click="false"
          v-model="dtNascimento"
          transition="scale-transition"
          offset-y
          full-width
          :nudge-bottom="-22"
          max-width="290px"
          :return-value.sync="dataNascimento"
        >
          <v-text-field
            slot="activator"
            label="Data nascimento"
            v-model="dataNascimento"
            append-icon="event"
            :rules="nascimentoRules"
            readonly
          ></v-text-field>
          <v-date-picker v-model="dataNascimento" no-title scrollable locale="pt-br">
            <v-spacer></v-spacer>
            <v-btn flat color="primary" @click="dtNascimento = false">Cancel</v-btn>
            <v-btn flat color="primary" @click="formatData(dataNascimento)">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <div class="d-flex">
        <v-text-field
          class="mr-2"
          slot="activator"
          label="CEP"
          v-model="vendedor.cep"
          placeholder="CEP"
          @blur="buscaCep"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          slot="activator"
          label="Cidade"
          v-model="vendedor.cidade"
          placeholder="Cidade"
          :readonly="cepEncontrado"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          slot="activator"
          label="Bairro"
          v-model="vendedor.bairro"
          placeholder="Bairro"
          :readonly="cepEncontrado"
        ></v-text-field>
      </div>
      <div class="d-flex">
        <v-text-field
          class="mr-2"
          slot="activator"
          label="Rua"
          v-model="vendedor.rua"
          placeholder="Rua"
          :readonly="cepEncontrado"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          slot="activator"
          label="Número"
          v-model="vendedor.numero"
          placeholder="Número"
        ></v-text-field>
        <v-text-field
          class="mr-2"
          slot="activator"
          label="Estado"
          v-model="vendedor.uf"
          placeholder="Estado"
          :readonly="cepEncontrado"
        ></v-text-field>
      </div>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark text @click="cancelar()">Cancelar</v-btn>
      <v-btn color="primary" dark text @click="salvarVendedor()">Salvar</v-btn>
    </v-card-actions>
  </v-flex>
</template>
<script>
import { serverBus } from "../../main"
import NovoVendedor from "../../domain/vendedor/NovoVendedor"
import SelectSituacoes from "../vendedor/selectSituacaoVendedor"
import SelectPraca from "../praca/selectPracaPorFuncionario.vue"
import { ServicoVendedor } from "../../servicos/servicoVendedor"
import { ServicoConsultaCep } from "../../servicos/servicoConsultaCep"
const servicoVendedor = new ServicoVendedor()
const servicoConsultaCep = new ServicoConsultaCep()
export default {
  components: {
    SelectPraca,
    SelectSituacoes
  },
  data() {
    return {
      dtNascimento: false,
      dataNascimento: null,
      vendedor: new NovoVendedor(),
      switch1: true,
      cpfValido: "",
      cepEncontrado: true,
      nascimentoRules: [v => !!v || "Campo obrigatório"],
      nomeRules: [v => !!v || "Campo obrigatório"],
      cpfRules: [v => !!v || "Campo obrigatório"],
      telefoneRules: [v => !!v || "Campo obrigatório"],
      sempreAtivo: false,
      codPraca: ""
    }
  },
  created() {
    serverBus.$on("pracaSelected", value => {
      this.codPraca = value
    })
    serverBus.$on("sempreAtivo", value => {
      this.sempreAtivo = true
      this.codPraca = value
    })
    serverBus.$on("situacaoSelected", value => {
      this.vendedor.situacao = value
    })
  },
  methods: {
    validaCpfCadastrado() {
      if (this.vendedor.cpf) {
        servicoVendedor.validarCPFjaExiste(this.vendedor.cpf).then(res => {
          if (res.status === 200) {
            if (res.data) {
              this.cpfValido = "done_outline"
            } else {
              this.cpfValido = "error_outline"
            }
          }
        })
      }
    },
    buscaCep() {
      var cep = this.vendedor.cep
      servicoConsultaCep.buscaCep(cep).then(res => {
        if (res.status === 200 && res.data.erro == undefined) {
          this.vendedor.rua = res.data.logradouro
          this.vendedor.bairro = res.data.bairro
          this.vendedor.cidade = res.data.localidade
          this.vendedor.uf = res.data.uf
          this.cepEncontrado = true
        } else {
          this.vendedor.rua = ""
          this.vendedor.bairro = ""
          this.vendedor.cidade = ""
          this.vendedor.uf = ""
          this.cepEncontrado = false
        }
      })
    },
    salvarVendedor() {
      this.vendedor.codPraca = this.codPraca
      if (this.sempreAtivo) {
        this.vendedor.situacao = 1
      }

      if (this.$refs.form.validate()) {
        servicoVendedor.salvarNovoVendedor(this.vendedor).then(res => {
          if (res.status === 200) {
            if (this.sempreAtivo) {
              this.fecharDialog()
              serverBus.$emit("mensagemSucesso", true)
            } else {
              this.$router.push({ name: "Vendedores" })
            }
          }
        })
      }
    },
    cancelar() {
      if (this.sempreAtivo) {
        this.fecharDialog()
      } else {
        this.$router.push({ name: "Vendedores" })
      }
    },
    fecharDialog() {
      serverBus.$emit("fecharDialog", false)
    },
    formatData(dataNascimento) {
      if (dataNascimento != null) {
        var parts = dataNascimento.split("-")
        var dataFormatada = parts[2] + "/" + parts[1] + "/" + parts[0]
        this.vendedor.dataNascimento = new Date(parts[0], parts[1] - 1, parts[2])
        this.$refs.dtNasc.save(dataFormatada)
      }
    }
  }
}
</script>
